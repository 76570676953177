
<template> 
<div class="header-container">
  <div class="header-row">
    <div class="header-column logo">
      <img src="/images/header/logo.png" alt="Logo" width="150">
    </div>
    <div class="header-column title">
      <h1 class="header_text">Care Girls Hostel</h1>
    </div>
    <div class="header-column action">
      <a href="https://myhostel.carehomebd.com" target="_blank" class="header-btn">Login</a>
    </div>
  </div>
</div>

</template>

<script>
  export default {

       methods: {
    handleScroll () {

    },
    backendUrl(){
      //window.location.href=process.env.VUE_APP_ENV_BACKENDURL;
      return process.env.VUE_APP_ENV_BACKENDURL;
    }
  },
  };
</script>

<style>

.header_text {
  font-size: 50px;
  font-weight: 500;
  font-family: 'Niconne', cursive;
  color: #e0d6e9;
  text-shadow: 2px 2px 0px  #957dad,
               4px 4px 0px #ee4b2b,
               6px 6px 0px #00c2cb,
               8px 8px 0px #ff7f50,
               10px 10px 0px #553c9a;
}


/* General header container styling */
.header-container {
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  text-align: left;
}

/* Flexbox row layout */
.header-row {
  display: flex;
  justify-content: space-between; /* Spreads the columns evenly */
  align-items: center; /* Vertically centers content */
  margin: 0 auto; /* Centers the content horizontally */
  padding: 0 20px; /* Adds padding to the sides */
}

/* Column for the logo */
.header-column.logo {
  width: 150px;
}

/* Column for the title or central content */
.header-column.title {
  flex-grow: 1; /* Makes this column take up the remaining space */
  text-align: left;
}

.header-column.title h1 {
  margin: 0;
}

/* Column for the call-to-action or login button */
.header-column.action {
  text-align: right;
}

.header-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color:rgb(255, 160, 122); /* Bootstrap blue color */
  color: rgb(10, 10, 10);
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.header-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

</style>