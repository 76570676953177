<template>
  <div id="app">
    <HeaderTop/>
    <br/>
    <Header/>
    <div v-if="loader" id="loader" class="spinner-border text-success"></div>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import HeaderTop from "./components/layout/HeaderTop";
import Footer from "./components/layout/Footer";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
  name: 'App',
  components: {
    Header,Footer, HeaderTop
  },
  computed:{
    loader() {
      return this.$store.getters.loader;
    },
  }
}
</script>

<style>
#loader{
  margin-left: 45%;
  margin-top: 15%;
  margin-bottom: 15%;
}
#app {
  font-family: Calibri, sans-serif;
  margin-top: 0;
  background-color: rgba(29,29,21);
}
.c_color{
  color: rgba(112, 131, 53);
}
#custom_navbar {
  background: rgba(255, 160, 122);
  color: #f4ff97 !important;
}
#custom_navbar ul li a{
  color: #0a0a0a !important;
}
#custom_navbar ul li{
  /* basic */
  background-color: rgba(255, 160, 122);
  margin: 0 auto;
  padding: 0px;
  text-align: center;
  /* border-radius */
  border-radius: 0px;
  border-collapse: collapse;
}
#custom_navbar{
  /* basic */
  background-color: rgba(255, 160, 122);
  margin: 0 auto;
  padding: 0px;
  text-align: center;
  /* border-radius */
  border-radius: 0px;
  /* box-shadow */
  box-shadow: rgba(145, 166, 69) 0 0 0 1px;
  border-collapse: collapse;
}
#custom_navbar .dropdown-item{
  background: rgba(255, 160, 122);
}
#custom_navbar .dropdown-item:hover, .dropdown-item:focus{
  background-color: rgba(255, 160, 122);
}
@media screen and (max-width: 600px) {
  #head_section {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
@media screen and (min-width: 780px) {
  #navbar_text {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  #custom_navbar ul li{
    /* basic */
    background-color: rgba(255, 160, 122);
    margin: 0 auto;
    padding: 2px;
    text-align: center;
    /* border-radius */
    border-radius: 2px;
    /* box-shadow */
    box-shadow: rgba(145, 166, 69) 1px 0 1px 2px;
    border-collapse: collapse;
  }
}
.dropdown-menu {
  background-color: rgb(38 36 35);
}
#searchbar_background{
  background: rgba(103,90,67);
  border: none;
}
#searchbar_background:focus{
  background: rgba(103,90,67);
  border: none;
  color: black;
}
#login_background{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  border: 1px solid black;
}
#login_background:active{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  border: 1px solid black;
}
#login_background:focus{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  border: 1px solid black;
}
#login_background:hover{
  background: rgba(114,98,65);
  color: rgba(73,60,56);
  border: 1px solid black;
}
</style>
